import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const SpinnerCard = () => {
  return (
    <div className='py-4'>
      <Box
        sx={{
          display: 'flex w-full flex-col justify-center items-center',
        }}
      >
        <CircularProgress />
      </Box>
    </div>
  );
};

export default SpinnerCard;
