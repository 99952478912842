export const colors = {
  ORANGE: '#FD7A33',
  ORANGE_LIGHT: 'rgba(255, 162, 84, 0.2)',
  BACKGROUND_WHITE: '#D9D9D9',
  BORDER_GREY: '#D9D9D9',
  WHITE_DARK: '#EBECF0',
  WHITE: '#FFFFFF',
  GRAY_30: '#C5C5C5',
  WHITE_GRADIENT: '#FFFFFF60',
  GREY_LIGHT: '#F7F7F7',
  GREY_MEDIUM: '#2E3138',
  GRAY_20: '#333',
  GREY_ICON: '#595959',
  GREY_BUTTON: '#5D5956',
  BLACK: '#000000',
  ORANGE_GRADIENT_DARK: '#FD7A33',
  ORANGE_WHITE: '#E1740F',
  ORANGE_LIGHTER: '#FA8E2B',
  ORANGE_GRADIENT_LIGHT: '#F6FAFB',

  ORANGE_GRADIENT_MEDIUM: 'rgba(253, 122, 51, 0.4)',
  GREY_MEDIUM: '#827C75',
  GREY_DARK: '#666666',
  GREY_BORDER: '#D0D5DD',
  BLUE_DARK: '#407AD1',
  BLUE_LIGHT: '#00BAF2',
  YELLOW: '#FCEA2B',
  YELLOW_MUSTARD: '#F4AA41',
  YELLOW_MEDIUM: '#ECD92D',
  GREEN: '#3F731D',
  RED: '#D22F27',
  VEG_GREEN: '#44AB18',
  NONVEG_RED: '#FE0700',
  SUCCESS_GREEN: '#5C9E31',
  FAILED_RED: '#DC3B30',
  WHATSAPP_GREEN: '#25D366',
  GREEN_SHADE: '#00B16A',
  DARK_GREEN: '#26A65B',
  WARNING_YELLOW: '#FFCC00',
  DARKER_GRAY: '#3D3D3D',
  DARKER_GRAY_TRANSPARENT: 'rgba(61,61,61, 0.5)',
  BACKGROUND_LIGHT: '#F7F6F6',
  WHITE_DARK: 'rgba(255, 255, 255, 0.25)',
  NEAR_BLACK: 'rgba(11, 11, 11, 0.85)',
  LIGHTER_GERY: '#7E7B7B',
  LESS_DARKER_GERY: 'rgba(61, 61, 61, 0.1)',
  GREY_BLUE: '#D0E2FF',
  HEADER_COLOR: '#5D5D5D',
  DISABLED_GREY: '#9D9D9D',
  BLUE_BORDER_COLOR: 'rgba(214, 214, 214, 0.5)',
  STAR_YELLOW_COLOR: '#F6AB27',
  PROFILE_BACKGROUD: 'rgba(153, 120, 81, 0.16)',
  BASE_BACKGROUND: 'gba(255, 255, 255, 0.25)',
  LESS_OPACITY_BLACK: '#00000080',
  STAR_YELLOW_COLOR: '#F6AB27',
  BLUE_BORDER_COLOR: 'rgba(214, 214, 214, 0.5)',
  DISABLED_GREY: '#C6C6C6',
  HEADER_COLOR: '#5D5D5D',
  LIGHTEST_GREY_75: 'rgba(93, 93, 93, 0.75)',
  LIGHTEST_GREY_FIVE: '#9D9D9D',
  LIGHT_DARK_GREY: '#7D7D7D',
  LIGHT_WHITE: '#F8F8F8',
  ORANGE_SHADE: '#FA8E2B',
  BASE_ORANGE: '#E1740F',
  IMAGE_BACKGROUD: 'rgba(217, 217, 217, 1)',
  FOLLOWERS_PINK: '#FFB389',
  ERROR_RED: '#EC5454',
  DARK_RED: '#CC4800',
  BACKGROUND_BLACK: '#141212',
  BUTTON_BLUE: '#5790DF',
};
