import { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import * as animationData from './../assets/animations/404.json';

const WebsiteNavigation = () => {
  useEffect(() => {
    window.location.href = 'https://froker.in';
  });

  return null;
};

export default WebsiteNavigation;
