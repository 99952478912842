import * as React from 'react';
import './TabIndicator.css';
import { SCREEN_WIDTH } from '../../utils';
const TabIndicator = (props) => {
  const tab = props?.tab;

  if (tab == 'left') {
    return (
      <img
        src={require('../../assets/icons/LeftTab.png')}
        style={{ height: 50 }}
      />
    );
  } else {
    return (
      <img
        src={require('../../assets/icons/rightTab.png')}
        style={{ height: 50 }}
      />
    );
  }
};

export default TabIndicator;
