import axiosRequest from '../utils/axiosRequest';
import ApiPath from '../redux/constants/ApiPath';

export const getProfileAffiliatePosts = (data) => {
  return axiosRequest.get(
    `${ApiPath.getProfileAffiliatePosts}/${data?.page}/${data?.limit}/${data?.fetchFeatured}/${data?.creatorProfileId}`
  );
};

export const getUserPosts = (userId) => {
  return axiosRequest.post(`${ApiPath.getUserProfile}`, {
    userId: userId,
  });
};

export const getAffiliatePost = (postId) => {
  return axiosRequest.get(`${ApiPath.getAffiliatePost}/${postId}`);
};
