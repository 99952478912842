import React, { useCallback, useEffect, useState } from 'react';
import { SCREEN_WIDTH } from '../../utils';
import { colors } from '../../utils/colors';
import FeaturedItemComponent from './FeaturedItemComponent';
import TitleHeader from '../../components/headers/TitleHeader';
import { getFeaturedProducts } from '../../services/productService';
// import InfiniteScroll from 'react-infinite-scroll-component';
import InfiniteScroll from 'react-infinite-scroller';
import Divider from '../../components/extras/Divider';
import PoweredByFroker from '../../components/PoweredByFroker';
import SpinnerCard from '../../components/cards/SpinnerCard';

const HandPickedItems = (props) => {
  const { creatorProfile } = props;
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const limit = 5;

  const fetchData = async () => {
    if (hasMore && !loading) {
      setLoading(true);
      await getFeaturedProducts({
        page: page,
        limit: limit,
        fetchFeatured: true,
        creatorProfileId: creatorProfile?._id,
      })
        .then((response) => response?.data)
        .then((responseData) => {
          setData((data) => data.concat(responseData?.products));
          setPage(
            responseData?.pagination?.next?.page
              ? responseData?.pagination?.next?.page
              : -1
          );
          setHasMore(responseData?.pagination?.next?.page ? true : false);
        });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // return <SpinnerCard />;
  if (data?.length == 0 && !loading) return null;
  if (data?.length == 0 && loading) return <SpinnerCard />;
  return (
    <div className={`flex flex-col bg-white ${data?.length > 0 && 'pt-3'}`}>
      {data?.length > 0 && (
        <TitleHeader
          title={'Explore Items'}
          subtitle={'Hand Picked By Prachi'}
        />
      )}
      {data?.length > 0 && (
        <div className='flex overflow-x-scroll hide-scroll-bar scrollbar-hide'>
          <div className='flex flex-nowrap lg:ml-3 md:ml-3 ml-3 '>
            <InfiniteScroll
              pageStart={0}
              loadMore={fetchData}
              hasMore={hasMore}
              loader={<SpinnerCard />}
              useWindow={false}
              className='flex'
            >
              {data?.map((item, index) => {
                return (
                  <div className='inline-block' key={index}>
                    <div className='overflow-hidden w-52'>
                      <FeaturedItemComponent
                        item={item}
                        className='inline-block'
                      />
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      )}
      {data?.length > 0 && <Divider />}
    </div>
  );
};

export default HandPickedItems;
