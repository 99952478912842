import * as React from 'react';
import PostHeader from '../../components/headers/PostHeader';
import PostContent from './PostContent';
import PostProducts from './PostProducts';
import ExploreFeaturedItems from '../myShopPage/ExploreFeaturedItems';
import Divider from '../../components/extras/Divider';
import { useLoaderData, useParams } from 'react-router-dom';
import { savePostInteraction } from '../../services/userService';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

const PostPage = (props) => {
  const navigate = useNavigate();

  const post = useLoaderData()?.data?.post;
  if (!post) {
    navigate('/error');
  }
  const saveVisit = async () => {
    await savePostInteraction(post?._id);
  };

  const setSessionStorage = () => {
    const storedUuid = sessionStorage.getItem(`sessionId-${post?._id}`);

    if (!storedUuid) {
      const newUuid = uuidv4();

      sessionStorage.setItem(`sessionId-${post?._id}`, newUuid);
      saveVisit();
    }
  };

  useEffect(() => {
    if (post?._id) {
      setSessionStorage();
    }
  }, []);

  return (
    <div className='bg-white'>
      <PostHeader post={post} />
      <PostContent item={post} />
      <PostProducts
        products={post?.products}
        collections={post?.collections}
        creatorProfile={post?.creatorProfile}
      />
      <Divider />
      <ExploreFeaturedItems
        isFilterable={false}
        creatorProfile={post?.creatorProfile}
      />
    </div>
  );
};

export default PostPage;
