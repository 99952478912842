import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../utils/colors';
import Button from 'react-bootstrap/Button';
import useResponsiveFontSize from '../../utils/responsive';

const DownloadButton = () => {
  const onNavigate = () => {
    window.open('https://froker.app.link', '_blank');
  };
  const fontSize = useResponsiveFontSize(36, 16);

  return (
    <Button
      variant='primary'
      size='sm'
      active
      style={{
        backgroundColor: colors.WHITE,
        borderColor: colors.WHITE,
        borderRadius: 30,
        padding: 8,
        paddingRight: 15,
        paddingLeft: 15,
        color: colors.BLACK,
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: fontSize,
      }}
      onClick={onNavigate}
    >
      <FontAwesomeIcon icon={faDownload} />
      {'   '}Download Froker app
    </Button>
  );
};

export default DownloadButton;
