import React from 'react';
import BrandComponent from './BrandComponent';
import TitleHeader from '../../components/headers/TitleHeader';
import Divider from '../../components/extras/Divider';

const LeadingBrands = (props) => {
  const { creatorProfile, brands } = props;

  if (brands?.length == 0) return null;
  return (
    <div className='flex flex-col bg-white m-auto p-auto'>
      <TitleHeader title={'Leading Brands'} subtitle={'You Can Choose'} />
      <div className='flex overflow-x-scroll hide-scroll-bar scrollbar-hide'>
        <div className='flex flex-nowrap lg:ml-3 md:ml-3 ml-3 '>
          {brands?.map((item, index) => {
            return (
              <div className='inline-block' key={index}>
                <div
                  className='overflow-hidden w-52'
                  style={
                    {
                      // width: SCREEN_WIDTH * 0.4,
                      // height: SCREEN_WIDTH * 0.45,
                    }
                  }
                >
                  <BrandComponent
                    item={item?.brand}
                    className='inline-block'
                    creatorProfileId={creatorProfile?._id}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Divider />
    </div>
  );
};

export default LeadingBrands;
