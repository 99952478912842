import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { SCREEN_WIDTH } from '../../utils';
import { colors } from '../../utils/colors';
import DownloadButton from '../../components/buttons/DownloadButton';
import FollowButton from '../../components/buttons/FollowButton';
import './ProfileHeader.css';
import { TransparentTabs } from './TransparentTabs';
import useResponsiveFontSize from '../../utils/responsive';
const ProfileHeader = (props) => {
  const { creatorProfile } = props;
  const followers =
    creatorProfile.instagramBussinessAccount?.instagram_business_account
      ?.followers_count;

  const fontSize = useResponsiveFontSize(36, 36, 0.08);

  const [screenWidth, setScreenWidth] = useState(0);
  useEffect(() => {
    const handleResize = () => {
      const screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        window.screen.width;

      setScreenWidth(screenWidth);
    };

    // Call the handleResize function when the window is resized
    window.addEventListener('resize', handleResize);

    // Call handleResize once initially to set the font size based on the initial screen width
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={{ backgroundColor: colors.BACKGROUND_BLACK }}>
      <div
        style={{
          background: `url(${creatorProfile?.profilePic})`,
          ...styles.imageContainer,
          height: screenWidth < 448 && screenWidth,
        }}
        className='backgroundHeader'
        // className='max-w-full w-full'
      >
        <div style={styles.headerContainer} className='restrictHeight'>
          <div style={styles.downloadButton}>
            <DownloadButton />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'end',
            }}
            className='gradient_background'
          >
            <Row className='w-full max-w-full'>
              <Col
                span={24}
                style={{ ...styles.headerText, fontSize: fontSize }}
                className={`text-left font-bold`}
              >
                {creatorProfile?.username}
              </Col>
            </Row>
            <Row className='px-3'>
              <Col span={8} className='flex'>
                <span>
                  <FollowButton creatorProfile={creatorProfile} />
                </span>
              </Col>
              {followers >= 0 && (
                <Col span={6} className='ml-2'>
                  <p style={styles.titleText}>{Number(followers)}</p>
                  <p style={styles.subtitleText}>Followers</p>
                </Col>
              )}
              {creatorProfile?.productsCount >= 0 && (
                <Col span={6} className='ml-2'>
                  <p style={styles.titleText}>
                    {creatorProfile?.productsCount}
                  </p>
                  <p style={styles.subtitleText}>Products</p>
                </Col>
              )}
            </Row>
            <Row>
              <Col
                span={24}
                style={styles.descriptionText}
                className='text-left px-3 max-w-max'
              >
                {creatorProfile?.bio}
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <TransparentTabs creatorProfile={creatorProfile} />
    </div>
  );
};

const styles = {
  headerText: {
    fontFamily: 'Be Vietnam Pro',
    fontWeight: 700,
    fontStyle: 'normal',
    // fontSize: 38,
    color: colors.WHITE,
    paddingTop: 10,
    textAlign: 'left',
    padding: 10,
  },
  titleText: {
    margin: 0,
    padding: 0,
    fontFamily: 'Be Vietnam Pro',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 16,
    color: colors.WHITE,
  },
  subtitleText: {
    margin: 0,
    padding: 0,
    fontFamily: 'Be Vietnam Pro',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 14,
    color: colors.WHITE,
  },
  descriptionText: {
    margin: 0,
    padding: 0,
    fontFamily: 'Be Vietnam Pro',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 14,
    color: colors.WHITE,
    padding: 20,
  },
  imageContainer: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    // minHeight: SCREEN_WIDTH,
  },
  downloadButton: {
    padding: 30,
    paddingRight: 20,
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'row',
  },
  headerContainer: {
    flex: 1,
    // height: SCREEN_WIDTH,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
};

export default ProfileHeader;
