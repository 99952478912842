import React, { useState } from 'react';
import PlayIcon from '../../assets/icons/PlayIcon';
import ItemsButton from '../../components/buttons/ItemsButton';
import { SCREEN_WIDTH, sliceText } from '../../utils';
import { colors } from '../../utils/colors';
import './PostVerticalComponent.css';
import VideoPlayer from '../../components/video/VideoPlayer';
import Divider from '../../components/extras/Divider';
import { Link } from 'react-router-dom';
import './postContent.css';
import InfiniteScroll from 'react-infinite-scroller';

const MediaComponent = ({ item, videoDimensions }) => {
  if (
    item?.post?.media_type == 'IMAGE' ||
    item?.post?.media_type == 'CAROUSEL_ALBUM'
  ) {
    return (
      <div className='contents'>
        <img
          src={item?.post?.thumbnail_url || item?.post?.media_url}
          style={{
            // width: SCREEN_WIDTH * 0.9,
            borderRadius: 15,
            // height: (SCREEN_WIDTH * 0.9 * 3) / 4,
            objectFit: 'cover',
          }}
          className={`post-content ${videoDimensions}`}
        />
      </div>
    );
  } else {
    return (
      <VideoPlayer
        src={item?.post?.media_url || item?.post?.thumbnail_url}
        thumbnail_url={item?.post?.thumbnail_url}
        videoDimensions={videoDimensions}
        style={{
          borderRadius: 15,
          objectFit: 'cover',
          // height: (SCREEN_WIDTH * 0.9 * 3) / 4,
          // width: SCREEN_WIDTH * 0.9,
        }}
      />
    );
  }
};
const PostHorizontalComponent = (props) => {
  const { item, creatorProfile } = props;

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showMore, setShowMore] = useState(false);

  const onClick = () => {
    setShowMore(!showMore);
  };
  return (
    <div className='flex flex-col justify-center align-center'>
      <Link
        to={
          item?.typeOfMedia == 'REELS'
            ? `/reels/${creatorProfile?.username}/${item?._id}`
            : `/post/${creatorProfile?.username}/${item?._id}`
        }
      >
        <div
          className={`flex flex-shrink-0 relative justify-center ${
            screenWidth < 448 ? 'w-screen' : ''
          }`}
        >
          <span className='absolute bottom-0 flex items-end justify-between w-full px-3'>
            {item?.typeOfMedia == 'REELS' && (
              <span className='flex p-3 items-end justify-between'>
                <PlayIcon />
              </span>
            )}
            <span className='p-3 w-full flex justify-end'>
              <ItemsButton item={item} />
            </span>
          </span>
          <MediaComponent item={item} videoDimensions={'h-80 w-11/12'} />
        </div>
      </Link>
      <p
        className='w-full max-w-fit p-3 pb-0 mb-0 text-left'
        style={{
          fontFamily: 'Inter',
          fontWeight: 400,
          fontStyle: 'normal',
          fontSize: 14,
          color: colors.DARKER_GRAY,
        }}
      >
        {showMore ? (
          <div style={{ maxHeight: 250, overflow: 'scroll' }}>
            <InfiniteScroll
              pageStart={0}
              loadMore={() => {}}
              hasMore={false}
              useWindow={false}
            >
              <span>{item?.postCaption}</span>
              <button onClick={onClick}>
                <span style={{ color: colors.BLUE_DARK }}>Read Less</span>
              </button>
            </InfiniteScroll>
          </div>
        ) : (
          <span>
            {sliceText(item?.postCaption, 220)}
            {String(item?.postCaption)?.length > 220 && (
              <button onClick={onClick}>
                <span style={{ color: colors.BLUE_DARK }}> Read More</span>
              </button>
            )}
          </span>
        )}
      </p>
      <Divider />
    </div>
  );
};

export default PostHorizontalComponent;
