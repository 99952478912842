import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../utils/colors';
import { useNavigate } from 'react-router-dom';

const PostHeader = (props) => {
  const { post } = props;
  const navigate = useNavigate();
  const goBack = () => {
    try {
      navigate(-1);
    } catch (error) {
      navigate(`/profile/${post?.creatorProfile?.username}`);
    }
  };
  return (
    <div className='flex items-center p-2 m-2'>
      <button onClick={goBack}>
        <FontAwesomeIcon
          icon={faChevronLeft}
          style={{ color: colors.BACKGROUND_BLACK, height: 20, width: 20 }}
        />
      </button>
      <img
        src={post?.creatorProfile?.profilePic}
        className='w-12 h-12 rounded-full mx-3 object-cover'
      />
      <div className='flex flex-col text-left'>
        <span className='pb-0' style={styles.titleText}>
          {post?.creatorProfile?.username}
        </span>
        <span style={styles.subtitleText}>{post?.creatorProfile?.bio}</span>
      </div>
    </div>
  );
};

const styles = {
  titleText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 16,
    color: colors.BACKGROUND_BLACK,
  },
  subtitleText: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: 14,
    color: colors.GREY_MEDIUM,
  },
};

export default PostHeader;
