import React from 'react';
import { SCREEN_WIDTH } from '../../utils';
import { colors } from '../../utils/colors';
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

const CollectionComponent = (props) => {
  const { item, creatorProfile, backgroundStyles = '', textStyle = '' } = props;

  return (
    <div
      className={`flex flex-col justify-center align-center p-2 max-w-52 ${backgroundStyles}`}
    >
      <Link to={`/collection/${creatorProfile?._id}/${item?._id}`}>
        <div className='flex flex-shrink-0 relative justify-center align-center'>
          <span className='absolute bottom-0 flex flex-col items-center justify-end w-full'>
            <span className='flex p-3 items-end justify-end w-full'>
              <span className='p-1 rounded-full'>
                <ChevronRightIcon
                  className='h-8 w-8 rounded-full text-white p-1'
                  aria-hidden='true'
                  style={{ backgroundColor: colors.ORANGE_LIGHTER }}
                />
              </span>
            </span>
          </span>
          <img
            src={item?.coverPic}
            style={{
              borderRadius: 15,
              objectFit: 'cover',
            }}
            className='h-60 w-52'
          />
        </div>
      </Link>
      <div className='w-full max-w-fit p-1 pb-0 mb-0'>
        <p style={styles.title} className={`mb-0 text-left ${textStyle}`}>
          {item?.collectionName}
        </p>
        <p style={styles.subtitle} className={`mb-0 text-left ${textStyle}`}>
          {item?.products?.length || item?.numberOfProducts || 0} Products
          Listed
        </p>
      </div>
    </div>
  );
};

const styles = {
  title: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 16,
    color: colors.BACKGROUND_BLACK,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2, // Number of lines to display
    WebkitBoxOrient: 'vertical',
  },
  subtitle: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 14,
    color: colors.DARKER_GRAY,
  },
};

export default CollectionComponent;
