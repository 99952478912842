import React from 'react';
import StarIcon from '../../assets/icons/StarIcon';
import { colors } from '../../utils/colors';

const TitleHeader = (props) => {
  const { title, subtitle } = props;
  return (
    <div className='mb-6 w-full px-10'>
      <div className='flex justify-start items-center'>
        <StarIcon className='px-2' />
        <span className='px-2' style={styles.titleText}>
          {title}
        </span>
        <hr className='h-px w-2/12 bg-black px-2' />
      </div>
      <div className='flex justify-end items-center'>
        <hr className='h-px w-1/12 bg-black px-2' />
        <span className='px-2' style={styles.subtitleText}>
          {subtitle}
        </span>
        <StarIcon className='px-2' />
      </div>
    </div>
  );
};

const styles = {
  titleText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 18,
    color: colors.ORANGE_LIGHTER,
  },
  subtitleText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 16,
    color: colors.BLACK,
  },
};

export default TitleHeader;
