import React from 'react';
import { colors } from '../../utils/colors';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy,
  faShareFromSquare,
  faClose,
} from '@fortawesome/free-solid-svg-icons';
import './ShareLinkModal.css';

const ShareLinkModal = (props) => {
  const { open, handleClose, url, title = '', subtitle = '' } = props;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
  };

  const handleNavigate = () => {
    window.open(url, '_blank');
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {})
      .catch((error) => {
        handleNavigate();
      });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style} className='rounded-3xl bg-white w-11/12'>
          <button onClick={handleClose} className='absolute right-6 top-6'>
            <FontAwesomeIcon
              icon={faClose}
              style={{ color: colors.DARKER_GRAY, height: 20, width: 20 }}
            />
          </button>

          <Typography
            id='modal-modal-title'
            variant='h6'
            component='h4'
            className='pb-2'
          >
            {title}
          </Typography>
          <Typography id='modal-modal-description' className='pb-3'>
            {subtitle}
          </Typography>
          <div className='flex'>
            <input
              type='text'
              className='pointer-events-none bg-gray-200 p-2 mr-2 rounded-xl textBox'
              value={url}
              // readOnly
              disabled={true}
              style={{ width: '60%' }}
            />
            <button
              className='mx-2 p-2 bg-gray-200 rounded-xl'
              onClick={copyToClipboard}
            >
              <FontAwesomeIcon
                icon={faCopy}
                style={{ color: colors.DARKER_GRAY, height: 20, width: 20 }}
              />
            </button>
            <button
              className='mx-2 p-2 bg-gray-200 rounded-xl'
              onClick={handleNavigate}
            >
              <FontAwesomeIcon
                icon={faShareFromSquare}
                style={{ color: colors.DARKER_GRAY, height: 20, width: 20 }}
              />
            </button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default ShareLinkModal;

// {
//   "short_name": "React App",
//   "name": "Create React App Sample",
//   "icons": [
//     {
//       "src": "favicon.ico",
//       "sizes": "64x64 24x24",
//       "type": "image/x-icon"
//     },
//     {
//       "src": "favicon16.png",
//       "sizes": "16x16",
//       "type": "image/png"
//     },
//     {
//       "src": "favicon32.png",
//       "sizes": "32x32",
//       "type": "image/png"
//     },
//     {
//       "src": "favicon192.png",
//       "type": "image/png",
//       "sizes": "192x192"
//     },
//     {
//       "src": "favicon512.png",
//       "type": "image/png",
//       "sizes": "512x512"
//     }
//   ],
//   "start_url": ".",
//   "display": "standalone",
//   "theme_color": "#000000",
//   "background_color": "#ffffff"
// }
