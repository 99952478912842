import React, { useState } from 'react';
import { colors } from '../../utils/colors';
import PostHorizontalComponent from './PostHorizontalComponent';
// import InfiniteScroll from 'react-infinite-scroll-component';
import { getProfileAffiliatePosts } from '../../services/postService';
import InfiniteScroll from 'react-infinite-scroller';
import PoweredByFroker from '../../components/PoweredByFroker';
import SpinnerCard from '../../components/cards/SpinnerCard';
const FeaturedProductPost = (props) => {
  const { creatorProfile } = props;
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const limit = 5;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loading, setLoading] = useState(false);

  const fetchMoreData = async () => {
    if (hasMore) {
      setLoading(true);
      await getProfileAffiliatePosts({
        page: page,
        limit: limit,
        fetchFeatured: false,
        creatorProfileId: creatorProfile?._id,
      })
        .then((response) => response?.data)
        .then((responseData) => {
          setData((data) => data.concat(responseData?.posts));
          setPage(
            responseData?.pagination?.next?.page
              ? responseData?.pagination?.next?.page
              : -1
          );
          setHasMore(responseData?.pagination?.next?.page ? true : false);
        });
      setLoading(false);
    }
  };

  if (data?.length == 0 && loading) return <SpinnerCard />;

  return (
    <div className='flex flex-col bg-white m-auto p-auto'>
      {data?.length > 0 && (
        <div className='p-3 pb-0 text-left'>
          <p
            style={{
              fontFamily: 'Inter',
              fontWeight: 600,
              fontStyle: 'normal',
              fontSize: 18,
              color: colors.BACKGROUND_BLACK,
              marginBottom: 0,
            }}
          >
            Explore my posts
          </p>
          <p
            style={{
              fontFamily: 'Inter',
              fontWeight: 400,
              fontStyle: 'normal',
              fontSize: 14,
              color: colors.BACKGROUND_BLACK,
            }}
          >
            Shop the looks showcased in my posts and videos.
          </p>
        </div>
      )}

      <div
        className=''
        style={{
          overflow: 'auto',
          display: 'flex',
          flexDirection: 'column-reverse',
        }}
      >
        <InfiniteScroll
          pageStart={0}
          loadMore={fetchMoreData}
          hasMore={hasMore}
          loader={
            <SpinnerCard />
            // <div className='loader' key={0}>
            //   Loading ...
            // </div>
          }
          useWindow={false}
        >
          {data?.map((item, index) => {
            return (
              <div
                className={`inline-block ${screenWidth >= 448 ? 'px-3' : ''}`}
                key={index}
              >
                <div className='overflow-hidden flex justify-center'>
                  <PostHorizontalComponent
                    item={item}
                    className='inline-block'
                    creatorProfile={creatorProfile}
                  />
                </div>
              </div>
            );
          })}
          <PoweredByFroker />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default FeaturedProductPost;
