import { useEffect, useState } from 'react';

const useResponsiveFontSize = (
  defaultFontSize,
  maxFontSize,
  multiplier = 0.05
) => {
  const [fontSize, setFontSize] = useState(defaultFontSize);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        window.screen.width;
      const newFontSize = Math.min(maxFontSize, screenWidth * multiplier);
      setFontSize(newFontSize);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [defaultFontSize, maxFontSize, multiplier]);

  return fontSize;
};

export default useResponsiveFontSize;
