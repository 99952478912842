import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../utils/colors';
import Button from 'react-bootstrap/Button';

const FollowButton = (props) => {
  const { creatorProfile } = props;

  const onNavigate = () => {
    window.open(
      creatorProfile?.user?.url
        ? creatorProfile?.user?.url
        : 'https://froker.app.link',
      '_blank'
    );
  };
  return (
    <Button
      variant='primary'
      size='sm'
      active
      style={{
        backgroundColor: colors.ORANGE_LIGHTER,
        borderColor: colors.ORANGE_LIGHTER,
        color: colors.WHITE,
        fontFamily: 'Be Vietnam Pro',
        fontWeight: 600,
        fontStyle: 'normal',
        fontSize: 16,
        borderRadius: 10,
        padding: 10,
      }}
      onClick={onNavigate}
    >
      <FontAwesomeIcon
        icon={faPlus}
        style={{ color: colors.WHITE }}
        height={10}
        width={10}
      />
      {'  '}Follow
    </Button>
  );
};

export default FollowButton;
