import React, { useEffect, useRef, useState } from 'react';
import './video.css';
import ReactPlayer from 'react-player';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeXmark, faVolumeOff } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../utils/colors';

const Player = ({ url }) => {
  return (
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url={url}
        width={300}
        height={300}
        playing
      />
    </div>
  );
};

function VideoPlayer({
  src,
  thumbnail_url,
  height,
  width,
  style,
  videoDimensions = '',
  strictVideoDimensions = false,
  muted = true,
  isMutable = false,
  isCarousel = false,
}) {
  const videoRef = useRef(null);
  const [isBuffering, setIsBuffering] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [mute, setIsMute] = useState(muted);
  const [showIcon, setShowIcon] = useState(muted);
  const [shouldAutoPlay, setShouldAutoPlay] = useState(!isCarousel);
  const muteUnmute = () => {
    setIsMute((mute) => !mute);
  };

  useEffect(() => {
    setShowIcon(true);
    const interval = setInterval(() => {
      setShowIcon(false);
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [mute]);

  const handleLoadedData = (e) => {
    if (e?.nativeEvent) setVideoLoaded(true);
    setIsBuffering(false);
  };

  const handleWaiting = () => {
    console.log('in ');
    setIsBuffering(true);
  };

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Play video when at least 50% of it is visible
    };

    const observer = new IntersectionObserver((entries) => {
      entries?.forEach((entry) => {
        if (entry?.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      });
    }, options);

    if (videoRef?.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (videoRef?.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  useEffect(() => {
    var videoState = document.querySelector('#video');
    if (videoState?.readyState >= 4 && videoLoaded) {
      if (isVisible && videoRef?.current) {
        videoRef?.current?.play();
      } else if (!isVisible && videoRef?.current) {
        videoRef?.current?.pause();
      }
    }
  }, [isVisible]);

  const LoadMuteUnmute = () => {
    return showIcon ? (
      mute ? (
        <FontAwesomeIcon
          icon={faVolumeXmark}
          color={colors.BLACK}
          style={{
            backgroundColor: colors.DARKER_GRAY_TRANSPARENT,
            padding: 15,
            borderRadius: 35,
            height: 30,
            width: 30,
          }}
        />
      ) : (
        <FontAwesomeIcon
          icon={faVolumeOff}
          color={colors.BLACK}
          style={{
            backgroundColor: colors.DARKER_GRAY_TRANSPARENT,
            padding: 15,
            borderRadius: 35,
            height: 30,
            width: 30,
          }}
        />
      )
    ) : (
      <div />
    );
  };
  return (
    <div className="contents relative">
      {thumbnail_url && !videoLoaded && (
        <img
          src={thumbnail_url}
          style={{
            position: 'absolute',
            borderRadius: 15,
            objectFit: 'cover',
          }}
          className={
            strictVideoDimensions
              ? videoDimensions
              : `${videoDimensions} post-content`
          }
        />
      )}
      {/* <div
        style={style}
        className={
          strictVideoDimensions
            ? `react-player ${videoDimensions}`
            : `react-player ${videoDimensions} post-content`
        }
        ref={videoRef}
      >
        <ReactPlayer
          // className='react-player '

          url={src}
          playing
          height={'100%'}
          width={'100%'}
          loop
          // muted
          playsinline
          onReady={handleLoadedData}
          onWaiting={handleWaiting}
        />
      </div> */}

      <video
        ref={videoRef}
        id="video"
        muted={mute}
        loop
        autoPlay={shouldAutoPlay}
        autoFocus={false}
        draggable={false}
        controls={false}
        onLoadedData={handleLoadedData}
        onWaiting={handleWaiting}
        style={style}
        className={
          strictVideoDimensions
            ? videoDimensions
            : `${videoDimensions} post-content`
        }
        playsInline={true}
      >
        <source src={src} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      {isMutable && (
        <button onClick={muteUnmute} className="z-10">
          <div className="absolute z-10 top-0 left-0 right-0 bottom-16 flex flex-col justify-center items-center mt-14">
            {showIcon && <LoadMuteUnmute />}
          </div>
        </button>
      )}
    </div>
  );
}

export default VideoPlayer;
