import React from 'react';
import { SCREEN_WIDTH } from '../../utils';
import { colors } from '../../utils/colors';
const SingleItem = ({ item }) => {
  return (
    <div className='flex m-2 bg-gray-800 bg-opacity-50 rounded-3xl'>
      <span style={styles.subtitle} className='mx-3 p-1 w-max'>
        {item?.productName}
      </span>
    </div>
  );
};
const RotatingComponent = (props) => {
  const { item } = props;

  return (
    <div className='flex overflow-x-scroll hide-scroll-bar scrollbar-hide'>
      {item?.map((i, index) => {
        return <SingleItem item={i} key={index} />;
      })}
    </div>
  );
};

const styles = {
  title: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: 16,
    color: colors.WHITE,
  },
  subtitle: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: 16,
    color: colors.WHITE,
  },
};

export default RotatingComponent;
