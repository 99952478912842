import axiosRequest from '../utils/axiosRequest';
import ApiPath from '../redux/constants/ApiPath';

export const getFeaturedProducts = (data) => {
  return axiosRequest.post(
    `${ApiPath.getProfileProducts}/${data?.page}/${data?.limit}/${data?.fetchFeatured}/${data?.creatorProfileId}`,
    data
  );
};

export const getUserCollections = (data) => {
  return axiosRequest.get(
    `${ApiPath.getUserCollections}/${data?.page}/${data?.limit}/${data?.creatorProfileId}`
  );
};

export const getCollectionDetails = (creatorProfileId, collectionId) => {
  return axiosRequest.get(
    `${ApiPath.getCollectionDetails}/${creatorProfileId}/${collectionId}`
  );
};

export const getUserBrands = (data) => {
  return axiosRequest.get(`${ApiPath.getUserBrands}/${data?.creatorProfileId}`);
};

export const getUserSellers = (data) => {
  return axiosRequest.get(
    `${ApiPath.getUserSellers}/${data?.creatorProfileId}`
  );
};

export const getAllProducts = (data) => {
  return axiosRequest.post(
    `${ApiPath.getProfileProducts}/${data?.page}/${data?.limit}/${data?.fetchFeatured}/${data?.creatorProfileId}`,
    { ...data, isWeb: true }
  );
};
