import React from 'react';
import { colors } from '../../utils/colors';
import Button from 'react-bootstrap/Button';
import ShareIconShots from '../../assets/icons/ShareIconShots';
import { Link } from 'react-router-dom';

const ShotsCollection = (props) => {
  const { item, creatorProfile } = props;

  return (
    <div className='flex justify-center items-center p-2 mx-2 bg-gray-800 bg-opacity-50 rounded-md min-w-80 max-w-80'>
      <div className='flex flex-shrink-0 relative justify-center items-center'>
        <img
          src={item?.coverPic}
          style={{
            height: 120,
            width: 120,
          }}
          className='object-cover w-full rounded-md max-h-fit'
        />
      </div>
      <div className='flex flex-col w-full max-w-fit max-h-fit px-2 justify-center text-left'>
        <p style={styles.title} className='mb-0'>
          {item?.collectionName}
        </p>
        <p style={styles.subtitle} className='mb-0'>
          {item?.numberOfProducts || 0} Products Listed
        </p>
        {/* <p style={styles.title} className='mb-0'>
          Rs. 2000
        </p> */}
        <Link to={`/collection/${creatorProfile?._id}/${item?._id}`}>
          <Button
            variant='primary'
            size='lg'
            active
            className='my-2 px-3 py-1 bg-transparent border border-white-500 text-white rounded-3xl'
            style={{
              fontFamily: 'Be Vietnam Pro',
              fontWeight: 400,
              fontStyle: 'normal',
              fontSize: 14,
              borderRadius: 26,
            }}
          >
            <span className='flex items-center justify-evenly'>
              <span className='mr-2'>Check out</span>
              <ShareIconShots size={'20'} />
            </span>
          </Button>
        </Link>
      </div>
    </div>
  );
};

const styles = {
  title: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: 16,
    color: colors.WHITE,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1, // Number of lines to display
    WebkitBoxOrient: 'vertical',
  },
  subtitle: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: 16,
    color: colors.WHITE,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2, // Number of lines to display
    WebkitBoxOrient: 'vertical',
  },
};

export default ShotsCollection;
