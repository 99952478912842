import React from 'react';
const Divider = (props) => {
  const customClass = props?.customClass || '';
  return (
    <div className='w-full align-center'>
      <hr
        className={`h-px my-4 border-0 dark:bg-gray-700 m-4 bg-black ${customClass}`}
      ></hr>
    </div>
  );
};

export default Divider;
