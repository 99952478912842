import axiosRequest from '../utils/axiosRequest';
import ApiPath from '../redux/constants/ApiPath';

export const getUserProfile = (userId) => {
  return axiosRequest.post(`${ApiPath.getUserProfile}`, {
    userId: userId,
  });
};

export const getCreatorProfile = (username) => {
  return axiosRequest.get(`${ApiPath.getCreatorProfile}/${username}`);
};

export const saveProfileVist = (creatorProfileId) => {
  return axiosRequest.post(`${ApiPath.saveProfileVist}`, {
    creatorProfileId: creatorProfileId,
  });
};

export const savePostInteraction = (postId) => {
  return axiosRequest.post(`${ApiPath.savePostInteraction}`, {
    postId: postId,
  });
};
