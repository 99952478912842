import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import ProfilePage from './profilePage/ProfilePage';
import ShotsPage from './shotsPage/ShotsPage';
import ErrorElement from './ErrorElement';
import PostPage from './postPage/PostPage';
import { getCreatorProfile, getUserProfile } from '../services/userService';
import { StrictMode } from 'react';
import { getAffiliatePost } from '../services/postService';
import { getCollectionDetails } from '../services/productService';
import CollectionPage from './collectionPage/collectionPage';
import BrandPage from './brandPage/brandPage';
import WebsiteNavigation from './WebsiteNavigation';

const router = createBrowserRouter([
  {
    path: '/profile/:username',
    element: <ProfilePage />,
    errorElement: <ErrorElement />,
    loader: ({ params }) => {
      return getCreatorProfile(params.username);
    },
    shouldRevalidate: () => false,
  },
  {
    path: '/post/:username/:postId',
    element: <PostPage />,
    errorElement: <ErrorElement />,
    loader: ({ params }) => {
      return getAffiliatePost(params.postId);
    },
  },
  {
    path: '/reels/:username/:postId',
    element: <ShotsPage />,
    errorElement: <ErrorElement />,
    loader: ({ params }) => {
      return getAffiliatePost(params.postId);
    },
  },
  {
    path: '/collection/:creatorProfileId/:collectionId',
    element: <CollectionPage />,
    errorElement: <ErrorElement />,
    loader: ({ params }) => {
      return getCollectionDetails(
        params?.creatorProfileId,
        params.collectionId
      );
    },
  },
  {
    path: '/brand/:creatorProfileId/:brandId',
    element: <BrandPage />,
    errorElement: <ErrorElement />,
  },
  {
    path: '/error',
    element: <ErrorElement />,
  },
  {
    path: '/',
    element: <WebsiteNavigation />,
    errorElement: <ErrorElement />,
  },
  // {
  //   path: '/*',
  //   element: <ErrorElement />,
  // },
]);

const RedirectToExternalSite = () => {
  // Redirect to the desired website
  window.location.href = 'https://froker.in'; // Change this to your desired website URL
  return null; // Return null to prevent rendering any component
};

const Router = () => {
  return (
    // <StrictMode>
    <RouterProvider router={router} />
    // </StrictMode>
  );
};

export default Router;
