import { useState } from 'react';
import Lottie from 'react-lottie';
import * as animationData from './../assets/animations/404.json';

const ErrorElement = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <div className='h-screen flex justify-center items-center'>
      <Lottie
        options={defaultOptions}
        height={200}
        width={400}
        isStopped={false}
        isPaused={false}
      />
    </div>
  );
};

export default ErrorElement;
