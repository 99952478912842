import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../utils/colors';
import ShareIconShots from '../../assets/icons/ShareIconShots';
import { useNavigate } from 'react-router-dom';
import ShareLinkModal from '../modal/ShareLinkModal';

const ShotsHeader = (props) => {
  const { item, baseAffiliateURL } = props;

  const [open, setOpen] = React.useState(false);
  // const [loading, setLoading]  = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const url = `${baseAffiliateURL}/reels/${item?.creatorProfile?.username}/${item?._id}`;
  const title = 'Share the reels link';
  const subtitle = item?.creatorProfile?.username;

  const navigate = useNavigate();
  const goBack = () => {
    try {
      navigate(-1);
    } catch (error) {
      navigate(`/profile/${item?.creatorProfile?.username}`);
    }
  };

  const handleClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          url: url,
        });
      } catch (error) {}
    } else {
      handleOpen();
    }
  };

  return (
    <div>
      <div className='flex z-30 p-3 w-full justify-between items-center'>
        <button onClick={goBack}>
          <FontAwesomeIcon
            icon={faArrowLeft}
            style={{ color: colors.WHITE, height: 20, width: 20 }}
          />
        </button>
        <button onClick={handleClick}>
          <ShareIconShots />
        </button>
      </div>

      <ShareLinkModal
        open={open}
        handleClose={handleClose}
        url={url}
        title={title}
        subtitle={''}
      />
    </div>
  );
};

export default ShotsHeader;
