import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../utils/colors';
import Button from 'react-bootstrap/Button';
import BookMarkIcon from '../../assets/icons/BookMarkIcon';

const ItemsButton = (props) => {
  const { item } = props;

  return (
    <span>
      <Button
        variant='primary'
        size='lg'
        active
        style={{
          backgroundColor: colors.WHITE,
          borderColor: colors.WHITE,
          color: colors.WHITE,
          fontFamily: 'Be Vietnam Pro',
          fontWeight: 600,
          fontStyle: 'normal',
          fontSize: 16,
          borderRadius: 27,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <BookMarkIcon />
        <span
          style={{
            color: colors.BACKGROUND_BLACK,
            marginLeft: 5,
            fontFamily: 'Be Vietnam Pro',
            fontWeight: 500,
            fontStyle: 'normal',
            fontSize: 13,
          }}
        >
          {item?.products?.length || item?.collections?.length || 0}+ Items
        </span>
      </Button>
    </span>
  );
};

export default ItemsButton;
