import * as React from 'react';
import Switch from '@mui/material/Switch';

export default function ToggleButton(props) {
  const { handleChange } = props;

  const label = { inputProps: { 'aria-label': 'Color switch demo' } };

  return (
    <Switch
      {...label}
      defaultChecked={false}
      color='default'
      onChange={handleChange}
    />
  );
}
