import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../utils/colors';
import Button from 'react-bootstrap/Button';
import ShareIconShots from '../../assets/icons/ShareIconShots';

const ShotsShareButton = (props) => {
  const { text = '', onClick = () => {} } = props;
  return (
    <Button
      variant='primary'
      size='lg'
      active
      className='my-2 px-3 py-1 bg-transparent border border-white-500 text-white rounded-3xl'
      style={{
        fontFamily: 'Be Vietnam Pro',
        fontWeight: 400,
        fontStyle: 'normal',
        fontSize: 14,
        borderRadius: 26,
      }}
      onClick={onClick}
    >
      <span className='flex items-center justify-evenly'>
        <span
          className='mr-2'
          style={{
            width: 50,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 1, // Number of lines to display
            WebkitBoxOrient: 'vertical',
          }}
        >
          {text}
        </span>
        <ShareIconShots size={'20'} />
      </span>
    </Button>
  );
};

export default ShotsShareButton;
