import * as React from 'react';
import Divider from '../../components/extras/Divider';
import { colors } from '../../utils/colors';
import PostProduct from './PostProduct';
import { SCREEN_WIDTH } from '../../utils';
import CollectionComponent from '../myShopPage/CollectionComponent';
import FeaturedItemComponent from '../myShopPage/FeaturedItemComponent';
const PostProducts = (props) => {
  const { products = [], collections = [], creatorProfile } = props;

  const Header = React.useCallback(() => {
    return (
      <div className='flex flex-col justify-center items-center'>
        <Divider customClass='mb-2' />
        <span
          style={{
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontStyle: 'normal',
            fontSize: 16,
            color: colors.DARKER_GRAY,
          }}
        >
          Listed{' '}
          {products?.length > 0
            ? `Products (${products?.length || 0})`
            : `Collections (${collections?.length || 0})`}
        </span>
        <Divider customClass='mt-2' />
      </div>
    );
  }, [products]);
  return (
    <div>
      {(products?.length > 0 || collections?.length > 0) && <Header />}
      <div className='flex overflow-x-scroll hide-scroll-bar scrollbar-hide'>
        <div className='flex flex-nowrap lg:ml-3 md:ml-3 ml-3 '>
          {products?.map((item, index) => {
            return (
              <div className='inline-block' key={index}>
                <div
                  className='overflow-hidden'
                  style={{
                    width: 200,
                    height: 350,
                  }}
                >
                  <FeaturedItemComponent item={item} />
                </div>
              </div>
            );
          })}
          {collections?.map((item, index) => {
            return (
              <div className='inline-block' key={index}>
                <div
                  className='overflow-hidden'
                  style={{
                    width: 200,
                    height: 300,
                  }}
                >
                  <CollectionComponent
                    item={item}
                    className='inline-block'
                    creatorProfile={creatorProfile}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default PostProducts;
