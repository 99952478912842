import * as React from 'react';
import { Carousel, IconButton } from '@material-tailwind/react';
import VideoPlayer from '../../components/video/VideoPlayer';
import { SCREEN_WIDTH } from '../../utils';
import { colors } from '../../utils/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const MediaComponent = ({ item, videoDimensions, isCarousel }) => {
  if (item?.media_type == 'IMAGE' || item?.media_type == 'CAROUSEL_ALBUM') {
    return (
      <img
        src={item?.thumbnail_url || item?.media_url}
        className="object-cover post-content"
      />
    );
  } else {
    return (
      <VideoPlayer
        src={item?.media_url}
        className="object-cover"
        style={{
          objectFit: 'cover',
        }}
        videoDimensions={videoDimensions}
        muted={false}
        isMutable={false}
        isCarousel
      />
    );
  }
};
const PostContent = (props) => {
  const { item } = props;
  const [activeMediaIndex, setActiveMediaIndex] = React.useState(0);
  const ref = React.useRef();

  return (
    <div>
      {item?.post?.children?.length > 0 ? (
        <Carousel
          className="w-full h-auto"
          prevArrow={({ handlePrev }) => (
            <IconButton
              variant="text"
              color="white"
              size="lg"
              onClick={handlePrev}
              className="!absolute top-2/4 left-4 -translate-y-2/4"
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                color={colors.WHITE}
                style={{
                  backgroundColor: colors.DARKER_GRAY_TRANSPARENT,
                  padding: 15,
                  borderRadius: 25,
                }}
              />
            </IconButton>
          )}
          nextArrow={({ handleNext }) => (
            <IconButton
              variant="text"
              color="white"
              size="lg"
              onClick={handleNext}
              className="!absolute top-2/4 !right-4 -translate-y-2/4"
            >
              <FontAwesomeIcon
                icon={faChevronRight}
                color={colors.WHITE}
                style={{
                  backgroundColor: colors.DARKER_GRAY_TRANSPARENT,
                  padding: 15,
                  borderRadius: 25,
                }}
              />
            </IconButton>
          )}
        >
          {item?.post?.children[0]?.data?.map((i, index) => {
            return <MediaComponent item={i} key={index} isCarousel={true} />;
          })}
        </Carousel>
      ) : (
        <MediaComponent item={item?.post} videoDimensions={'large-content'} />
      )}
      <p
        className="w-full max-w-fit p-3 pb-0 mb-0"
        style={{
          fontFamily: 'Inter',
          fontWeight: 400,
          fontStyle: 'normal',
          fontSize: 16,
          color: colors.DARKER_GRAY,
        }}
      >
        {item?.postCaption}
      </p>
    </div>
  );
};

export default PostContent;
