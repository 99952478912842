import * as React from 'react';
import ShotsHeader from '../../components/headers/ShotsHeader';
import VideoPlayer from '../../components/video/VideoPlayer';
import ShotsProfile from './ShotsProfile';

const ShotsContent = (props) => {
  const { item, baseAffiliateURL } = props;

  if (!item) return null;
  return (
    <div className='relative snap-start shots-default'>
      <VideoPlayer
        src={item?.post?.media_url}
        style={{
          objectFit: 'cover',
        }}
        videoDimensions='shots-full'
        strictVideoDimensions={true}
        muted={true}
        isMutable={true}
      />
      <div className='absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-between h-full w-full'>
        <ShotsHeader item={item} baseAffiliateURL={baseAffiliateURL} />
        <ShotsProfile item={item} />
      </div>
    </div>
  );
};

export default ShotsContent;
