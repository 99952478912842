import { colors } from '../../utils/colors';
import MyPostPage from '../myPostsPage/MyPostsPage';
import MyShopPage from '../myShopPage/MyShopPage';
import TabIndicator from '../../components/extras/TabIndicator';
import { useState } from 'react';
import Divider from '../../components/extras/Divider';

const TabPanel = ({ children, activeTab }) => {
  return (
    <div className='mt-2'>
      {activeTab == 1 ? (
        <TabIndicator tab='left' />
      ) : (
        <TabIndicator tab='right' />
      )}
      <div className={`${activeTab ? 'block' : 'hidden'} bg-white`}>
        {children}
      </div>
    </div>
  );
};

const TabBar = ({ tabs, activeTab, onTabChange }) => {
  return (
    <div className='flex w-full'>
      {tabs.map((tab) => (
        <button
          key={tab.id}
          className={`flex-1 ${
            activeTab === tab.id
              ? 'bg-transparent text-white'
              : 'bg-transparent text-white'
          } py-3 px-4`}
          onClick={() => onTabChange(tab.id)}
          style={{
            fontFamily: 'Inter',
            fontWeight: 600,
            fontStyle: 'normal',
            fontSize: 16,
          }}
        >
          <span
            className={activeTab === tab.id ? `border-b-2 pb-2` : ''}
            style={{
              borderColor: colors.ORANGE_LIGHTER,
              marginLeft: tab.id == 1 ? 40 : 0,
              marginRight: tab.id == 2 ? 40 : 0,
            }}
          >
            {tab.label}
          </span>
        </button>
      ))}
    </div>
  );
};

export function TransparentTabs(props) {
  const { creatorProfile } = props;
  const tabs = [
    { id: 1, label: 'My Posts' },
    { id: 2, label: 'My Shop' },
  ];

  const content = {
    1: <MyPostPage creatorProfile={creatorProfile} />,
    2: <MyShopPage creatorProfile={creatorProfile} />,
  };

  const [activeTab, setActiveTab] = useState(1);

  const handleTabChange = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <div className='mx-0'>
      <div className='w-full align-center'>
        <hr
          className={`h-px m-3 my-0 mb-2 border-0 dark:bg-gray-700 bg-white`}
        ></hr>
      </div>
      <TabBar tabs={tabs} activeTab={activeTab} onTabChange={handleTabChange} />
      <TabPanel activeTab={activeTab}>{content[activeTab]}</TabPanel>
    </div>
  );
}
