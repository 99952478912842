import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { colors } from '../../utils/colors';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function SelectLabels(props) {
  const { data, selected, setSelected } = props;

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button
          className='inline-flex w-max justify-center gap-x-1.5 rounded-3xl px-3 py-2 text-sm font-semibold text-black shadow-sm ring-1 ring-inset ring-black-300 items-center border border-gray-900'
          style={{
            backgroundColor: colors.WHITE,
            ...styles.subtitleText,
          }}
        >
          {selected == '' ? 'Brands' : selected}
          <ChevronDownIcon
            className='-mr-1 h-6 w-6 text-black'
            aria-hidden='true'
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute z-10 mt-2 w-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none h-96 overflow-scroll'>
          <div className='py-1'>
            <Menu.Item>
              {({ active }) => (
                <button
                  type='submit'
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block w-full px-4 py-2 text-left text-sm w-max'
                  )}
                  onClick={() => {
                    setSelected('');
                  }}
                >
                  All
                </button>
              )}
            </Menu.Item>
            {data?.map((item, index) => {
              return (
                <Menu.Item
                  key={index}
                  className={
                    selected == item?.productSeller ? 'bg-gray-100' : ''
                  }
                >
                  {({ active }) => (
                    <button
                      type='submit'
                      className={classNames(
                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                        'block w-full px-4 py-2 text-left text-sm w-max min-w-52',
                        selected == item?.productSeller
                          ? 'text-lg bg-gray-100'
                          : 'text-sm'
                      )}
                      style={{ width: '-webkit-fill-available' }}
                      onClick={() => {
                        setSelected(item?.productSeller);
                      }}
                    >
                      {item?.productSeller}
                    </button>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}

const styles = {
  titleText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 18,
    color: colors.BACKGROUND_BLACK,
    marginBottom: 0,
  },
  subtitleText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 15,
    color: colors.BACKGROUND_BLACK,
  },
};
