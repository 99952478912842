import React, { useEffect, useState } from 'react';
import Divider from '../../components/extras/Divider';
import CategorySection from './CategorySection';
import ExploreFeaturedItems from './ExploreFeaturedItems';
import HandPickedItems from './HandPickedItems';
import LeadingBrands from './LeadingBrands';
import Collections from './Collections';
import { getUserBrands } from '../../services/productService';

const MyShopPage = (props) => {
  const { creatorProfile } = props;
  const [brands, setBrands] = useState([]);

  const fetchData = async () => {
    await getUserBrands({
      creatorProfileId: creatorProfile?._id,
    })
      .then((response) => response?.data)
      .then((responseData) => {
        setBrands(responseData?.brands);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <div>
      {/* <CategorySection /> */}
      <HandPickedItems creatorProfile={creatorProfile} />
      <Collections creatorProfile={creatorProfile} />
      <LeadingBrands creatorProfile={creatorProfile} brands={brands} />
      <ExploreFeaturedItems
        isFilterable={true}
        creatorProfile={creatorProfile}
        brands={brands}
      />
    </div>
  );
};

export default MyShopPage;
