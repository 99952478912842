import React from 'react';

const ShareIcon = (props) => {
  const { color = '#3D3D3D' } = props;
  return (
    <svg
      width='22'
      height='21'
      viewBox='0 0 22 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.4511 10.97L16.4511 15.0536C16.4511 15.4607 16.2894 15.851 16.0016 16.1388C15.7138 16.4266 15.3235 16.5883 14.9164 16.5883H6.47561C6.06859 16.5883 5.67823 16.4266 5.39042 16.1388C5.10261 15.851 4.94092 15.4607 4.94092 15.0536V6.61282C4.94092 6.20579 5.10261 5.81544 5.39042 5.52763C5.67823 5.23982 6.06859 5.07813 6.47561 5.07812H10.1324'
        stroke={color}
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12.6011 4.18262H17.2052V8.7867'
        stroke={color}
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M10.8706 10.6575L17.0095 4.51855'
        stroke={color}
        strokeWidth='1.2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default ShareIcon;
