import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { colors } from '../../utils/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

export default function ButtonChip(props) {
  const onPress = props?.onPress;
  const { text = 'Electronics', id, selectedBrand, icon = null } = props;
  const isSelected = id == selectedBrand;
  return (
    <div className='relative mx-1'>
      {/* Button */}
      <button
        onClick={onPress}
        className={`flex justify-evenly ${
          isSelected ? 'text-white' : 'text-black'
        } border border-gray-900 ${
          isSelected ? '' : 'bg-transparent'
        } font-bold py-2 px-4 rounded-3xl w-max`}
        style={{
          ...styles.subtitleText,
          backgroundColor:
            id == selectedBrand ? colors.ORANGE_LIGHTER : colors?.WHITE,
        }}
      >
        {text} {icon && icon}
      </button>
    </div>
  );
}

const styles = {
  titleText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 18,
    color: colors.BACKGROUND_BLACK,
    marginBottom: 0,
  },
  subtitleText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 14,
    color: colors.BACKGROUND_BLACK,
  },
};
