import * as React from 'react';
import { colors } from '../../utils/colors';
import ShotsProducts from './ShotsProducts';
import RotatingComponent from './RotatingComponent';
import ToggleButton from '../../components/buttons/ToggleButton';
import InfiniteScroll from 'react-infinite-scroller';
import { sliceText } from '../../utils';

const ShotsProfile = (props) => {
  const { item } = props;
  const [isToggled, setIsToggled] = React.useState(false);
  const [showMore, setShowMore] = React.useState(false);

  const onClick = () => {
    setShowMore(!showMore);
  };

  const handleChange = () => {
    setIsToggled(!isToggled);
  };

  const Header = React.useCallback(() => {
    return (
      <div className='flex items-center p-2 m-2'>
        <img
          src={item?.creatorProfile?.profilePic}
          className='w-12 h-12 rounded-full mr-3 ml-0 object-cover'
        />
        <div className='flex flex-col'>
          <span className='pb-0 text-left' style={styles.titleText}>
            {item?.creatorProfile?.username}
          </span>
        </div>
      </div>
    );
  }, [item]);

  return (
    <div className='w-full z-30'>
      <Header />
      <ShotsProducts
        hidden={!isToggled}
        products={item?.products}
        collections={item?.collections}
        creatorProfile={item?.creatorProfile}
      />
      <p style={styles.subtitleText} className='m-2 mx-4 text-left'>
        {showMore ? (
          <div>
            <div style={{ maxHeight: 250, overflow: 'scroll' }}>
              <InfiniteScroll
                pageStart={0}
                loadMore={() => {}}
                hasMore={false}
                useWindow={false}
              >
                <span>{item?.postCaption}</span>
                <button onClick={onClick}>
                  <span style={{ color: colors.BLUE_DARK }}>Read Less</span>
                </button>
              </InfiniteScroll>
            </div>
          </div>
        ) : (
          <span>
            {sliceText(item?.postCaption, 220)}
            {String(item?.postCaption)?.length > 220 && (
              <button onClick={onClick}>
                <span style={{ color: colors.BLUE_DARK }}> Read More</span>
              </button>
            )}
          </span>
        )}
      </p>
      {(item?.products?.length > 0 || item?.collections?.length > 0) && (
        <div className='flex w-full justify-between'>
          <RotatingComponent item={item?.products} />
          <ToggleButton handleChange={handleChange} />
        </div>
      )}
    </div>
  );
};

const styles = {
  titleText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 16,
    color: colors.WHITE,
  },
  subtitleText: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: 15,
    color: colors.WHITE,
  },
};

export default ShotsProfile;
