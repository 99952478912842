import React, { useEffect, useState } from 'react';
import { colors } from '../../utils/colors';
// import InfiniteScroll from 'react-infinite-scroll-component';
import InfiniteScroll from 'react-infinite-scroller';
import FeaturedItemComponent from '../myShopPage/FeaturedItemComponent';
import { useLoaderData, useParams } from 'react-router-dom';
import CollectionHeader from '../../components/headers/CollectionHeader';
import ShareLinkModal from '../../components/modal/ShareLinkModal';
import PoweredByFroker from '../../components/PoweredByFroker';
import EmptyCard from '../../components/cards/EmptyCard';
import SpinnerCard from '../../components/cards/SpinnerCard';
import { useNavigate } from 'react-router-dom';

const CollectionPage = (props) => {
  const navigate = useNavigate();

  const collection = useLoaderData()?.data?.collection;
  if (!collection) {
    navigate('/error');
  }
  const creatorProfile = useLoaderData()?.data?.creatorProfile;
  const [open, setOpen] = useState(false);
  // const [loading, setLoading]  = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const url = `${creatorProfile?.baseAffiliateURL}/collection/${creatorProfile?._id}/${collection?._id}`;
  const title = 'Share the product link';
  const subtitle = collection?.collectionName;

  const fetchData = () => {};
  const handleClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: subtitle,
          url: url,
        });
      } catch (error) {
        handleOpen();
      }
    } else {
      handleOpen();
    }
  };

  if (collection?.length == 0) {
    return <EmptyCard />;
  }

  return (
    <div className='flex flex-col bg-white m-auto p-auto'>
      <CollectionHeader
        collection={collection}
        handleClick={handleClick}
        creatorProfile={creatorProfile}
      />
      {/* <div className='p-3 pb-0'>
        <p style={styles.titleText}>Explore Listed products</p>
        <p style={styles.subtitleText}>
          Check out your Favourite products from the list
        </p>
      </div> */}

      <InfiniteScroll
        dataLength={collection?.products}
        next={fetchData}
        hasMore={false}
        scrollThreshold={0.5}
        style={{ display: 'flex' }}
        loader={<SpinnerCard />}
      >
        <div className='grid grid-cols-2 gap-0 p-2'>
          {collection?.products?.map((item, index) => {
            return (
              <div
                key={index}
                className='flex flex-col justify-center items-center'
              >
                <FeaturedItemComponent item={item} className='p-4' />
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
      <ShareLinkModal
        open={open}
        handleClose={handleClose}
        url={url}
        title={title}
        subtitle={subtitle}
      />
      <PoweredByFroker />
    </div>
  );
};

const styles = {
  titleText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 18,
    color: colors.BACKGROUND_BLACK,
    marginBottom: 0,
  },
  subtitleText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 14,
    color: colors.BACKGROUND_BLACK,
  },
};

export default CollectionPage;
