import React, { useEffect, useState } from 'react';
import { SCREEN_WIDTH } from '../../utils';
import { colors } from '../../utils/colors';
import FeaturedItemComponent from './FeaturedItemComponent';
import SelectionDropdown from './SelectionDropdown';
import ButtonChip from '../../components/buttons/ButtonChip';
import { getAllProducts, getUserSellers } from '../../services/productService';
import InfiniteScroll from 'react-infinite-scroll-component';
import PoweredByFroker from '../../components/PoweredByFroker';
import SpinnerCard from '../../components/cards/SpinnerCard';

const ExploreFeaturedItems = (props) => {
  // function generateArray(n) {
  //   return Array.from({ length: n + 1 }, (_, index) => index);
  // }

  const { creatorProfile, brands } = props;
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedSeller, setSelectedSeller] = useState('');
  const [sortBy, setSortBy] = useState('');
  const [loading, setLoading] = useState(false);
  const limit = 5;
  const [sellers, setSellers] = useState([]);

  const fetchData = async (
    page_,
    limit_,
    selectedBrand_,
    selectedSeller_,
    sortBy_,
    hasMore_
  ) => {
    if (hasMore_ && !loading) {
      setLoading(true);
      await getAllProducts({
        page: page_,
        limit: limit_,
        fetchFeatured: false,
        creatorProfileId: creatorProfile?._id,
        selectedBrandId: selectedBrand_,
        selectedSeller: selectedSeller_,
        sortBy: sortBy_,
      })
        .then((response) => response?.data)
        .then((responseData) => {
          setData((data) => data.concat(responseData?.products));
          if (responseData?.pagination?.next?.page) {
            setPage((page) => page + 1);
          } else {
            setPage((page) => -1);
          }

          setHasMore(responseData?.pagination?.next?.page ? true : false);
        });
      setLoading(false);
    }
  };

  const getSellers = async () => {
    if (creatorProfile?._id) {
      await getUserSellers({ creatorProfileId: creatorProfile?._id })
        .then((response) => response?.data)
        .then((data) => {
          setSellers(data?.sellers);
        });
    }
  };

  const onPressHandler = async (brand) => {
    setData((data) => []);
    setPage((page) => 1);
    setHasMore((hasMore) => true);
    setLoading((loading) => false);
    setSelectedBrand((selectedBrand) => (brand ? brand?._id : null));
    await fetchData(1, limit, brand?._id, selectedSeller, sortBy, true);
  };

  useEffect(() => {
    getSellers();
  }, [creatorProfile]);

  const onSelectSeller = async (seller_) => {
    setSelectedSeller((selectedSeller) => seller_);
    setData((data) => []);
    setPage((page) => 1);
    setHasMore((hasMore) => true);
    setLoading((loading) => false);
    await fetchData(1, limit, selectedBrand, seller_, sortBy, true);
  };

  return (
    <div
      className='flex flex-col bg-white m-auto p-auto'
      style={{ minHeight: 300 }}
    >
      <div className='p-3 pb-0 text-left'>
        <p style={styles.titleText}>Explore Listed products</p>
        <p style={styles.subtitleText}>
          Check out your Favourite products from the list
        </p>
      </div>
      {props?.isFilterable && (
        <div className='flex px-2'>
          <SelectionDropdown
            data={sellers}
            selected={selectedSeller}
            setSelected={onSelectSeller}
          />
          <div className='flex overflow-x-scroll hide-scroll-bar scrollbar-hide'>
            {brands?.map((brand, index) => {
              return (
                <ButtonChip
                  key={index}
                  id={brand?.brand?._id}
                  text={brand?.brand?.brandName}
                  selectedBrand={selectedBrand}
                  onPress={() => {
                    if (brand?.brand?._id == selectedBrand) {
                      onPressHandler(null);
                    } else {
                      onPressHandler(brand?.brand);
                    }
                  }}
                />
              );
            })}
          </div>
        </div>
      )}
      <InfiniteScroll
        dataLength={data.length}
        next={() => {
          fetchData(
            page,
            limit,
            selectedBrand,
            selectedSeller,
            sortBy,
            hasMore
          );
        }}
        hasMore={hasMore}
        scrollThreshold={0.5}
        style={{ display: 'flex' }}
        // loader={<SpinnerCard />}
      >
        <div className='grid grid-cols-2 gap-0 p-2'>
          {data?.map((item, index) => {
            return (
              <div
                key={index}
                className='flex flex-col justify-center items-center'
              >
                <FeaturedItemComponent item={item} />
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
      {loading && <SpinnerCard />}
      <PoweredByFroker />
    </div>
  );
};

const styles = {
  titleText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 18,
    color: colors.BACKGROUND_BLACK,
    marginBottom: 0,
  },
  subtitleText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 14,
    color: colors.BACKGROUND_BLACK,
  },
};

export default ExploreFeaturedItems;
