import React, { useCallback, useEffect, useState } from 'react';
import { SCREEN_WIDTH } from '../../utils';
import { colors } from '../../utils/colors';
import FeaturedItemComponent from './FeaturedItemComponent';
import TitleHeader from '../../components/headers/TitleHeader';
import {
  getFeaturedProducts,
  getUserCollections,
} from '../../services/productService';
// import InfiniteScroll from 'react-infinite-scroll-component';
import CollectionComponent from './CollectionComponent';
import Divider from '../../components/extras/Divider';
import InfiniteScroll from 'react-infinite-scroller';
import PoweredByFroker from '../../components/PoweredByFroker';
import SpinnerCard from '../../components/cards/SpinnerCard';

const Collections = (props) => {
  const { creatorProfile } = props;
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const limit = 5;

  const fetchData = async () => {
    if (hasMore && !loading) {
      setLoading(true);
      await getUserCollections({
        page: page,
        limit: limit,
        creatorProfileId: creatorProfile?._id,
      })
        .then((response) => response?.data)
        .then((responseData) => {
          setData((data) => data.concat(responseData?.collections));
          if (responseData?.pagination?.next?.page) {
            setPage((page) => page + 1);
          } else {
            setPage((page) => -1);
          }

          setHasMore(responseData?.pagination?.next?.page ? true : false);
        });
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (data?.length == 0 && loading) return <SpinnerCard />;

  return (
    <div className='flex flex-col bg-white'>
      {data?.length > 0 && (
        <div className='p-3 pb-0'>
          <p style={styles.titleText} className='text-left'>
            Featured Collections
          </p>
          <p style={styles.subtitleText} className='text-left'>
            Discover Froker's Curated Product Collection
          </p>
        </div>
      )}
      {data?.length > 0 && (
        <div className='flex overflow-x-scroll hide-scroll-bar scrollbar-hide'>
          <div className='flex flex-nowrap lg:ml-3 md:ml-3 ml-3 '>
            <InfiniteScroll
              pageStart={0}
              loadMore={fetchData}
              hasMore={hasMore}
              loader={<SpinnerCard />}
              useWindow={false}
              className='flex'
            >
              {data?.map((item, index) => {
                return (
                  <div className='inline-block' key={index}>
                    <div className='overflow-hidden w-52'>
                      <CollectionComponent
                        item={item}
                        creatorProfile={creatorProfile}
                        className='inline-block'
                      />
                    </div>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      )}
      {data?.length > 0 && <Divider />}
    </div>
  );
};

const styles = {
  titleText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 18,
    color: colors.BACKGROUND_BLACK,
    marginBottom: 0,
  },
  subtitleText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 14,
    color: colors.BACKGROUND_BLACK,
  },
};

export default Collections;
