import React, { useCallback, useEffect, useState } from 'react';
import { colors } from '../../utils/colors';
// import InfiniteScroll from 'react-infinite-scroll-component';
import FeaturedItemComponent from '../myShopPage/FeaturedItemComponent';
import { useParams } from 'react-router-dom';
import { getFeaturedProducts } from '../../services/productService';
import InfiniteScroll from 'react-infinite-scroller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import PoweredByFroker from '../../components/PoweredByFroker';
import EmptyCard from '../../components/cards/EmptyCard';
import SpinnerCard from '../../components/cards/SpinnerCard';

const BrandPage = (props) => {
  // const products = useLoaderData()?.data?.products;

  const { creatorProfileId, brandId } = useParams();

  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const limit = 5;

  const fetchData = useCallback(async () => {
    if (hasMore && !loading) {
      setLoading(true);
      await getFeaturedProducts({
        page: page,
        limit: limit,
        fetchFeatured: false,
        creatorProfileId: creatorProfileId,
        selectedBrandId: brandId,
      })
        .then((response) => response?.data)
        .then((responseData) => {
          setData((data) => data.concat(responseData?.products));
          setPage(
            responseData?.pagination?.next?.page
              ? responseData?.pagination?.next?.page
              : -1
          );
          setHasMore(responseData?.pagination?.next?.page ? true : false);
        });
      setLoading(false);
    }
  }, [page, hasMore, loading]);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  if (data?.length == 0 && !loading && !hasMore) {
    return <EmptyCard />;
  }

  return (
    <div
      id='scrollableDiv'
      className='flex flex-col bg-white m-auto p-auto min-h-max'
      // style={{ height: 1000 }}
    >
      <div class='bg-white py-4 px-4 flex justify-between items-center min-w-max'>
        <button onClick={goBack} class='text-black text-lg'>
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: colors.BLACK, height: 20, width: 20 }}
          />
        </button>
        <div className='min-w-max flex justify-center items-center pr-4 w-full'>
          <h1 class='text-black text-lg' style={styles.titleText}>
            Myntra Products
          </h1>
        </div>
      </div>
      {/* <CollectionHeader products={products} /> */}
      {/* <div className='p-3 pb-0'>
        <p style={styles.titleText}>Explore Listed products</p>
        <p style={styles.subtitleText}>
          Check out your Favourite products from the list
        </p>
      </div> */}

      <InfiniteScroll
        pageStart={0}
        loadMore={fetchData}
        hasMore={hasMore}
        loader={
          <SpinnerCard />
          // <div className='loader' key={0}>
          //   Loading ...
          // </div>
        }
        useWindow={false}
      >
        <div className='grid grid-cols-2 gap-0 p-2'>
          {data?.map((item, index) => {
            return (
              <div
                key={index}
                className='flex flex-col justify-center items-center'
              >
                <FeaturedItemComponent item={item} className='p-4' />
              </div>
            );
          })}
        </div>
        <PoweredByFroker />
      </InfiniteScroll>
    </div>
  );
};

const styles = {
  titleText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 18,
    color: colors.BACKGROUND_BLACK,
    marginBottom: 0,
  },
  subtitleText: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 14,
    color: colors.BACKGROUND_BLACK,
  },
};

export default BrandPage;
