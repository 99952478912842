import React, { useState } from 'react';
import PlayIcon from '../../assets/icons/PlayIcon';
import ItemsButton from '../../components/buttons/ItemsButton';
import { SCREEN_WIDTH } from '../../utils';
import './PostVerticalComponent.css';
import VideoPlayer from '../../components/video/VideoPlayer';
import { Link } from 'react-router-dom';

const MediaComponent = ({ item, videoDimensions }) => {
  if (
    item?.post?.media_type == 'IMAGE' ||
    item?.post?.media_type == 'CAROUSEL_ALBUM'
  ) {
    return (
      <img
        src={item?.post?.thumbnail_url || item?.post?.media_url}
        style={{
          // width: SCREEN_WIDTH * 0.5,
          borderRadius: 15,
          // height: (SCREEN_WIDTH * 0.5 * 4) / 3,
          objectFit: 'cover',
        }}
        className={`post-content ${videoDimensions}`}
      />
    );
  } else {
    return (
      <VideoPlayer
        src={item?.post?.media_url}
        thumbnail_url={item?.post?.thumbnail_url}
        videoDimensions={videoDimensions}
        style={{
          borderRadius: 15,
          objectFit: 'cover',
          // height: (SCREEN_WIDTH * 0.5 * 4) / 3,
          // width: SCREEN_WIDTH * 0.5,
        }}
      />
    );
  }
};

const PostVerticalComponent = (props) => {
  const { item, creatorProfile } = props;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  return (
    <Link
      to={
        item?.typeOfMedia == 'REELS'
          ? `/reels/${creatorProfile?.username}/${item?._id}`
          : `/post/${creatorProfile?.username}/${item?._id}`
      }
    >
      <div className='flex-shrink-0 relative'>
        <span className='absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-between w-full'>
          {item?.typeOfMedia == 'REELS' ? (
            <span className='flex p-3 items-end justify-end w-full'>
              <PlayIcon />
            </span>
          ) : (
            <div />
          )}
          <span className='p-3 w-full'>
            <ItemsButton item={item} />
          </span>
        </span>
        <MediaComponent item={item} videoDimensions={'h-80 w-full'} />
      </div>
    </Link>
  );
};

export default PostVerticalComponent;
