import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../utils/colors';
import { useNavigate } from 'react-router-dom';
import ButtonChip from '../buttons/ButtonChip';
import ShareIcon from '../../assets/icons/ShareIcon';

const CollectionHeader = (props) => {
  const { collection, handleClick, creatorProfile } = props;
  const navigate = useNavigate();
  const goBack = () => {
    try {
      navigate(-1);
    } catch (error) {
      navigate(`/profile/${creatorProfile?.username}`);
    }
  };
  return (
    <div className='flex items-center justify-between'>
      <div className='flex items-center p-2 m-2'>
        <button onClick={goBack}>
          <FontAwesomeIcon
            icon={faChevronLeft}
            style={{ color: colors.BACKGROUND_BLACK, height: 20, width: 20 }}
          />
        </button>
        <img
          src={collection?.coverPic}
          className='w-16 h-16 rounded-xl mx-3 object-cover'
        />
        <div className='flex flex-col text-left'>
          <span className='pb-0' style={styles.titleText}>
            {collection?.collectionName}
          </span>
          <span style={styles.subtitleText}>
            {collection?.products?.length} Products Listed
          </span>
        </div>
      </div>
      <div className='mx-2'>
        <ButtonChip
          text={'Share'}
          icon={<ShareIcon color={colors.WHITE} />}
          onPress={handleClick}
        />
      </div>
    </div>
  );
};

const styles = {
  titleText: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 16,
    color: colors.BACKGROUND_BLACK,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2, // Number of lines to display
    WebkitBoxOrient: 'vertical',
  },
  subtitleText: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: 14,
    color: colors.GREY_MEDIUM,
  },
};

export default CollectionHeader;
