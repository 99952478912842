import * as React from 'react';
import ShotsContent from './ShotsContent';
import { useLoaderData } from 'react-router-dom';
import { savePostInteraction } from '../../services/userService';
import { v4 as uuidv4 } from 'uuid';
import './ShotsPage.css';
import { useNavigate } from 'react-router-dom';

const ShotsPage = (props) => {
  const navigate = useNavigate();

  const post = useLoaderData()?.data?.post;
  if (!post) {
    navigate('/error');
  }
  const baseAffiliateURL = useLoaderData()?.data?.baseAffiliateURL;

  const saveVisit = async () => {
    await savePostInteraction(post?._id);
  };

  const setSessionStorage = () => {
    const storedUuid = sessionStorage.getItem(`sessionId-${post?._id}`);

    if (!storedUuid) {
      const newUuid = uuidv4();

      sessionStorage.setItem(`sessionId-${post?._id}`, newUuid);
      saveVisit();
    }
  };

  React.useEffect(() => {
    if (post?._id) {
      setSessionStorage();
    }
  }, []);

  return (
    <div className='h-screen snap-mandatory snap-always snap-y overflow-scroll'>
      <ShotsContent item={post} baseAffiliateURL={baseAffiliateURL} />
    </div>
  );
};

export default ShotsPage;
