import React from 'react';
import FrokerLogo from '../assets/icons/FrokerLogo';
import { colors } from '../utils/colors';

const PoweredByFroker = () => {
  return (
    <div className='flex flex-col items-center justify-center w-full py-5'>
      <span
        style={{
          fontFamily: 'Inter',
          fontWeight: 400,
          fontSize: 15,
          color: colors.DARKER_GRAY,
        }}
        className='my-1'
      >
        Powered By
      </span>
      <span className='flex items-center justify-center'>
        <FrokerLogo />
        <span
          className='px-2'
          style={{
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: 16,
          }}
        >
          Froker
        </span>
      </span>
    </div>
  );
};

export default PoweredByFroker;
