import React from 'react';
import { colors } from '../../utils/colors';
import ShotsShareButton from '../../components/buttons/ShotsShareButton';
import ShareLinkModal from '../../components/modal/ShareLinkModal';

const ShotsProduct = (props) => {
  const { item } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const url = item?.productAffiliateURL || item?.productURL;
  const title = 'Share the product link';
  const subtitle = item?.productName;

  const onClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: subtitle,
          url: url,
        });
      } catch (error) {
        handleOpen();
      }
    } else {
      handleOpen();
    }
  };

  return (
    <div className='flex justify-center items-center p-2 mx-2 bg-gray-800 bg-opacity-50 rounded-md min-w-80 max-w-80'>
      <div className='flex flex-shrink-0 relative justify-center items-center'>
        <img
          src={item?.productCoverPic}
          style={{
            height: 120,
            width: 120,
          }}
          className='object-cover w-full rounded-md max-h-fit'
        />
      </div>
      <div className='flex flex-col w-full max-w-fit max-h-fit px-2 justify-center text-left'>
        <p style={styles.title} className='mb-0'>
          {item?.productSeller}
        </p>
        <p style={styles.subtitle} className='mb-0'>
          {item?.productName}
        </p>
        {/* <p style={styles.title} className='mb-0'>
          Rs. 2000
        </p> */}
        <ShotsShareButton text={item?.productSeller} onClick={onClick} />
      </div>
      <ShareLinkModal
        open={open}
        handleClose={handleClose}
        url={url}
        title={title}
        subtitle={subtitle}
      />
    </div>
  );
};

const styles = {
  title: {
    fontFamily: 'Inter',
    fontWeight: 700,
    fontStyle: 'normal',
    fontSize: 16,
    color: colors.WHITE,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1, // Number of lines to display
    WebkitBoxOrient: 'vertical',
  },
  subtitle: {
    fontFamily: 'Inter',
    fontWeight: 500,
    fontStyle: 'normal',
    fontSize: 16,
    color: colors.WHITE,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2, // Number of lines to display
    WebkitBoxOrient: 'vertical',
  },
};

export default ShotsProduct;
