import React from 'react';
import ShareIcon from '../../assets/icons/ShareIcon';
import { colors } from '../../utils/colors';
import ShareLinkModal from '../../components/modal/ShareLinkModal';

const FeaturedItemComponent = (props) => {
  const { item } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const url = item?.productAffiliateURL || item?.productURL;
  const title = 'Share the product link';
  const subtitle = item?.productName;

  const handleClick = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: title,
          text: subtitle,
          url: url,
        });
      } catch (error) {
        handleOpen();
      }
    } else {
      handleOpen();
    }
  };

  return (
    <div className='flex flex-col justify-center align-center p-2 max-w-52'>
      <div className='flex flex-shrink-0 relative justify-center align-center'>
        <span className='absolute bottom-0 flex flex-col items-center justify-end w-full'>
          <span className='flex p-3 items-end justify-end w-full'>
            <button onClick={handleClick} className='bg-white p-1 rounded-full'>
              <ShareIcon />
            </button>
          </span>
        </span>
        <img
          src={item?.productCoverPic}
          style={{
            borderRadius: 25,
            objectFit: 'cover',
          }}
          className='w-52 h-60'
        />
      </div>
      <div className='w-full max-w-fit p-1 pb-0 mb-0 '>
        <p style={styles.title} className='mb-0 text-left'>
          {item?.productSeller}
        </p>
        <p style={styles.subtitle} className='mb-0 text-left'>
          {item?.productName}
        </p>
        <p style={styles.title} className='mb-0 text-left'>
          Rs. {item?.productPrice}
        </p>
      </div>
      <ShareLinkModal
        open={open}
        handleClose={handleClose}
        url={url}
        title={title}
        subtitle={subtitle}
      />
    </div>
  );
};

const styles = {
  title: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 16,
    color: colors.BACKGROUND_BLACK,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 1, // Number of lines to display
    WebkitBoxOrient: 'vertical',
  },
  subtitle: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 14,
    color: colors.DARKER_GRAY,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2, // Number of lines to display
    WebkitBoxOrient: 'vertical',
  },
};

export default FeaturedItemComponent;
