import { useLoaderData, useParams } from 'react-router-dom';
import ProfileHeader from './ProfileHeader';
import { Helmet } from 'react-helmet';
import { saveProfileVist } from '../../services/userService';
import { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

const ProfilePage = (props) => {
  // const params = useParams();
  const navigate = useNavigate();

  const creatorProfile = useLoaderData()?.data?.creatorProfile;

  console.log('creatorProfile', creatorProfile);
  if (!creatorProfile) {
    navigate('/error');
  }
  const saveVisit = async () => {
    await saveProfileVist(creatorProfile?._id);
  };

  const setSessionStorage = () => {
    const storedUuid = sessionStorage.getItem(
      `sessionId-${creatorProfile?._id}`
    );
    if (!storedUuid) {
      const newUuid = uuidv4();

      sessionStorage.setItem(`sessionId-${creatorProfile?._id}`, newUuid);
      saveVisit();
    }
  };

  useEffect(() => {
    if (creatorProfile?._id) {
      setSessionStorage();
    }
  }, []);

  if (!creatorProfile) return null;

  return (
    <div>
      <Helmet>
        <meta property='og:image' content={creatorProfile?.profilePic} />
      </Helmet>
      <ProfileHeader creatorProfile={creatorProfile} />
    </div>
  );
};

export default ProfilePage;
