import FeaturedProductPost from './FeaturedProductPosts';
import TrendingPostCarousel from './TrendingPostCarousel';

const MyPostPage = (props) => {
  const { creatorProfile } = props;

  return (
    <div className='border-0 divide-none'>
      <TrendingPostCarousel creatorProfile={creatorProfile} />
      <FeaturedProductPost creatorProfile={creatorProfile} />
    </div>
  );
};

export default MyPostPage;
