import React from 'react';
import ShareIcon from '../../assets/icons/ShareIcon';
import ItemsButton from '../../components/buttons/ItemsButton';
import { SCREEN_WIDTH } from '../../utils';
import { colors } from '../../utils/colors';
import { Link } from 'react-router-dom';

const BrandComponent = (props) => {
  const { item, creatorProfileId } = props;

  return (
    <Link
      to={`/brand/${creatorProfileId}/${item?._id}`}
      style={{ textDecoration: 'none' }}
    >
      <div className='flex flex-col justify-center align-center p-2'>
        <div className='flex flex-shrink-0 relative justify-center align-center'>
          <img
            src={item?.brandLogo}
            style={{
              borderRadius: 15,
              objectFit: 'cover',
              // height: SCREEN_WIDTH * 0.4,
              // width: SCREEN_WIDTH * 0.4,
              borderColor: colors.BORDER_GREY,
              borderWidth: 1.5,
            }}
          />
        </div>
        <p
          style={styles.title}
          className='w-full p-1 pb-0 mb-0 flex align-center justify-center'
        >
          {item?.brandName}
        </p>
      </div>
    </Link>
  );
};

const styles = {
  title: {
    fontFamily: 'Inter',
    fontWeight: 600,
    fontStyle: 'normal',
    fontSize: 16,
    color: colors.BACKGROUND_BLACK,
  },
  subtitle: {
    fontFamily: 'Inter',
    fontWeight: 400,
    fontStyle: 'normal',
    fontSize: 14,
    color: colors.DARKER_GRAY,
  },
};

export default BrandComponent;
