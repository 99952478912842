import React, { useCallback, useEffect, useState } from 'react';
import { SCREEN_WIDTH } from '../../utils';
import { colors } from '../../utils/colors';
import PostVerticalComponent from './PostVerticalComponent';
import { getProfileAffiliatePosts } from '../../services/postService';
// import InfiniteScroll from 'react-infinite-scroll-component';
import InfiniteScroll from 'react-infinite-scroller';
import PoweredByFroker from '../../components/PoweredByFroker';
import SpinnerCard from '../../components/cards/SpinnerCard';
import Divider from '../../components/extras/Divider';
const TrendingPostCarousel = (props) => {
  const { creatorProfile } = props;
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const limit = 5;

  const fetchData = async () => {
    if (hasMore) {
      setLoading(true);
      await getProfileAffiliatePosts({
        page: page,
        limit: limit,
        fetchFeatured: true,
        creatorProfileId: creatorProfile?._id,
      })
        .then((response) => response?.data)
        .then((responseData) => {
          setData((data) => data.concat(responseData?.posts));
          setPage(
            responseData?.pagination?.next?.page
              ? responseData?.pagination?.next?.page
              : -1
          );
          setHasMore(responseData?.pagination?.next?.page ? true : false);
        });
      setLoading(false);
    }
  };

  if (data?.length == 0 && loading) return <SpinnerCard />;

  return (
    <div className='flex flex-col bg-white m-auto p-auto'>
      {data?.length > 0 && (
        <div className='p-3 pb-0 text-left'>
          <p
            style={{
              fontFamily: 'Inter',
              fontWeight: 600,
              fontStyle: 'normal',
              fontSize: 18,
              color: colors.BACKGROUND_BLACK,
              marginBottom: 0,
            }}
          >
            Featured Posts
          </p>
          <p
            style={{
              fontFamily: 'Inter',
              fontWeight: 400,
              fontStyle: 'normal',
              fontSize: 14,
              color: colors.BACKGROUND_BLACK,
            }}
          >
            Discover Froker's Curated Posts & Products just for you!
          </p>
        </div>
      )}
      <div className='flex overflow-x-scroll hide-scroll-bar scrollbar-hide'>
        <div className='flex flex-nowrap lg:ml-3 md:ml-3 ml-3 '>
          {/* <InfiniteScroll
            dataLength={data.length}
            next={fetchData}
            hasMore={hasMore}
            scrollThreshold={0.8}
            style={{ display: 'flex' }}
            loader={<h4>Loading...</h4>}
          > */}
          <InfiniteScroll
            pageStart={0}
            loadMore={fetchData}
            hasMore={hasMore}
            loader={
              <SpinnerCard />
              // <div className='loader' key={0}>
              //   Loading ...
              // </div>
            }
            useWindow={false}
            className='flex'
          >
            {data?.map((item, index) => {
              return (
                <div className='inline-block px-1' key={index}>
                  <div
                    className='overflow-hidden w-60'
                    // style={{ width: SCREEN_WIDTH * 0.5 }}
                  >
                    <PostVerticalComponent
                      item={item}
                      className='inline-block'
                      creatorProfile={creatorProfile}
                    />
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
      {data?.length > 0 && <Divider />}
    </div>
  );
};

export default TrendingPostCarousel;
