// request
import axios from 'axios';
import { BASE_URL } from '../redux/constants';
const axiosRequest = axios.create({
  baseURL: BASE_URL,
});

axiosRequest.interceptors.request.use(
  async (request) => {
    const token = localStorage?.getItem('session')?.split(`"`)[1];
    if (token === null) {
      request.headers = {
        'content-type': 'application/json',
      };
    } else {
      request.headers = {
        'content-type': 'application/json',
        Authorization: `Bearer ${token}`,
      };
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosRequest.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    if (error?.response?.status === 401) {
      alert('please login to continue');
    } else if (error?.response?.status === 404) {
      // alert(error?.response?.data?.message);
    } else if (error?.response?.status === 400) {
      if (error?.response?.data?.message) {
        // alert(error?.response?.data?.message);
      } else if (error?.response?.data?.error) {
        // alert(error?.response?.data?.error);
      }
    } else if (error.response?.status === 500) {
      // alert(error?.response?.data?.message);
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosRequest;
