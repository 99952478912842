import React, { useState } from 'react';
import PhoneIcon from '../../assets/icons/PhoneIcon';
import { colors } from '../../utils/colors';

const EmptyCard = () => {
  return (
    <div className='w-full flex flex-col justify-center items-center my-5'>
      <PhoneIcon />
      <div className='flex flex-col w-4/5'>
        <span
          style={{
            fontFamily: 'Inter',
            fontSize: 30,
            fontWeight: 700,
            color: colors.ORANGE_LIGHTER,
          }}
        >
          Oh No!
        </span>
        <span
          style={{
            fontFamily: 'Inter',
            fontSize: 16,
            fontWeight: 400,
          }}
        >
          No Products from this Brand have been Listed by Froker Yet.
        </span>
      </div>
    </div>
  );
};

export default EmptyCard;
