export default {
  getUserProfile: '/users/getUserProfileData',
  getCreatorProfile: '/webView/getCreatorProfile',
  getProfileAffiliatePosts: 'webView/getProfileAffiliatePosts',
  getProfileProducts: 'webView/getProfileProducts',
  getUserBrands: 'webView/getUserBrands',
  getUserSellers: 'webView/getUserSellers',
  getAffiliatePost: 'webView/getAffiliatePost',
  getUserCollections: 'webView/getUserCollections',
  getCollectionDetails: 'webView/getCollectionDetails',

  saveProfileVist: 'webView/saveProfileVist',
  savePostInteraction: 'webView/savePostInteraction',
};
