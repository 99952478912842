import * as React from 'react';
import ShotsProduct from './ShotsProduct';
import ShotsCollection from './ShotsCollection';
const ShotsProducts = (props) => {
  const { products = [], collections = [], creatorProfile } = props;
  const { hidden } = props;

  return (
    <div
      className={`flex overflow-x-scroll hide-scroll-bar scrollbar-hide ${
        hidden && 'collapse'
      }`}
    >
      <div className='flex flex-nowrap lg:ml-3 md:ml-3 ml-3 my-2'>
        {products?.map((product, index) => {
          return (
            <div className='inline-block z-20' key={index}>
              <div
                className='overflow-hidden'
                style={
                  {
                    // width: SCREEN_WIDTH * 0.8,
                  }
                }
              >
                <ShotsProduct item={product} className='inline-block' />
              </div>
            </div>
          );
        })}
        {collections?.map((product, index) => {
          return (
            <div className='inline-block z-20' key={index}>
              <div
                className='overflow-hidden'
                style={
                  {
                    // width: SCREEN_WIDTH * 0.8,
                  }
                }
              >
                <ShotsCollection
                  item={product}
                  className='inline-block'
                  creatorProfile={creatorProfile}
                  backgroundStyles={'mx-2 bg-gray-800 bg-opacity-50 rounded-md'}
                  textStyle={'text-white'}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ShotsProducts;
