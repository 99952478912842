import React from 'react';

const PlayIcon = () => {
  return (
    <svg
      width='31'
      height='31'
      viewBox='0 0 31 31'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M17.1349 12.1883L12.4949 8.73109V22.3185L17.1349 18.8612L21.6228 15.5248L17.1349 12.1883ZM17.1349 12.1883L12.4949 8.73109V22.3185L17.1349 18.8612L21.6228 15.5248L17.1349 12.1883ZM17.1349 12.1883L12.4949 8.73109V22.3185L17.1349 18.8612L21.6228 15.5248L17.1349 12.1883ZM14.0162 3.55279V0.503174C10.9584 0.805116 8.17435 2.01288 5.92279 3.83963L8.08307 5.99852C9.77174 4.70017 11.7951 3.82454 14.0162 3.55279ZM5.93801 8.12721L3.76251 5.98342C1.92171 8.21779 0.704655 10.9806 0.400391 14.0151H3.47346C3.7473 11.8109 4.62967 9.80299 5.93801 8.12721ZM3.47346 17.0345H0.400391C0.704655 20.069 1.92171 22.8318 3.76251 25.0662L5.93801 22.9073C4.62967 21.2466 3.7473 19.2387 3.47346 17.0345ZM5.92279 27.2099C8.17435 29.0367 10.9736 30.2445 14.0162 30.5464V27.4968C11.7951 27.225 9.77174 26.3494 8.08307 25.0511L5.92279 27.2099ZM30.7508 15.5248C30.7508 23.3149 24.7872 29.7463 17.1349 30.5464V27.4968C23.0985 26.7117 27.7081 21.6391 27.7081 15.5248C27.7081 9.41046 23.0985 4.33784 17.1349 3.55279V0.503174C24.7872 1.30332 30.7508 7.73468 30.7508 15.5248Z'
        fill='white'
      />
    </svg>
  );
};

export default PlayIcon;
